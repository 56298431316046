/* eslint-disable */
/**
 * Trust All Scripts
 *
 * This is a dirty little script for iterating over script tags
 * of your Ghost posts and adding them to the document head.
 *
 * This works for any script that then injects content into the page
 * via ids/classnames etc.
 *
 */

const $ = require("jquery");
const { includes } = require("lodash");
const { removeHellobarUrl } = require("./static/constants");
const axios = require("axios");

const hellobarUrls = require(`/static/constants`).removeHellobarUrl;
const url = window.location.pathname;

var customScripts = function () {
    $(document).ready(function () {
        window.addEventListener("load", videoScroll);
        window.addEventListener("scroll", videoScroll);

        function videoScroll() {
            if (document.querySelectorAll("video").length > 0) {
                var windowHeight = window.innerHeight,
                    videoEl = document.querySelectorAll("video");

                for (var i = 0; i < videoEl.length; i++) {
                    var thisVideoEl = videoEl[i],
                        videoHeight = thisVideoEl.clientHeight,
                        videoClientRect =
                            thisVideoEl.getBoundingClientRect().top;
                    if (
                        videoClientRect <= windowHeight - videoHeight * 0.5 &&
                        videoClientRect >= 0 - videoHeight * 0.5
                    ) {
                        thisVideoEl.play();
                    } else {
                        thisVideoEl.pause();
                    }
                }
            }
        }
        var isiDevice = /ipad|iphone|ipod/i.test(
            navigator.userAgent.toLowerCase()
        );
        if (isiDevice == true) {
            $("video").hide();
            $(".mob_featuresImg").show();
        } else {
            $("video").show();
            $(".mob_featuresImg").hide();
        }
        function addCommas(comma) {
            var parts = comma.toString().split(".");
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return parts.join(".");
        }
        var counterNumber = 0;
        // $(window).scroll(function() {
        //   var oTop = $('.navbar').offset().top - window.innerHeight;
        //   if (counterNumber == 0 && $(window).scrollTop() > oTop) {
        //     $('.counter-value span').each(function() {
        //       var $this = $(this),
        //         countTo = $this.attr('data-count');
        //       $({
        //         countNum: $this.text()
        //       }).animate({
        //           countNum: countTo
        //         },

        //         {
        //           duration: 1000,
        //           step: function() {
        //             $this.text(Math.floor(this.countNum));
        //           },
        //           complete: function() {
        //             $this.text(addCommas(this.countNum));
        //           }

        //         });
        //     });
        //     counterNumber = 1;
        //   }

        // });

        $(".mobile_listing").on("click", ".mobile_listing", function () {
            $(this).find(".dropdownmenu").toggleClass("mobileMenuD");
        });
        $(".mobileheader_menu li a").click(function () {
            $(".mobile_navbar .navbar-collapse").toggleClass("show");
        });
        $(".listingMenu > a").click(function (event) {
            event.preventDefault();
        });
        $(".box_item .plusText").click(function () {
            $(".box_item").find(".plusDisplayText").removeClass("activeBox");
            $(".box_item").find(".plusText").addClass("activeBox");
            $(this)
                .parent(".box_item")
                .children(".plusDisplayText")
                .toggleClass("activeBox");
            $(this)
                .parent(".box_item")
                .children(".plusText")
                .toggleClass("activeBox");
        });
        $(".scrollToDownload").click(function () {
            var href = $(this).attr("href");
            $("html, body").animate(
                { scrollTop: $(href).offset().top - 130 },
                500
            );
        });
        $(".scrollto").click(function () {
            var href = $(this).attr("href");
            $("html, body").animate(
                { scrollTop: $(href).offset().top - 250 },
                1000
            );
        });
        $(".section-collapsible-title").click(function () {
            $(this).toggleClass("down-arrow");
        });
        $(".caseFilter").click(function () {
            $(".caseFilter .useCasesBoxes").removeClass("active");
            $("#useCases .usecaseDesc").removeClass("active");
            $("#useCase .col-lg-3").removeClass("active");
            $("#useCasez .col-lg-3").removeClass("active");
            $("#useCasesz .usecaseDesc").removeClass("active");
            var getClassName = $(this).children(".useCasesBoxes").attr("attr");
            $(this).children(".useCasesBoxes").addClass("active");
            $("#useCases")
                .children("#" + getClassName)
                .addClass("active");
            $("#useCasesz")
                .children("#" + getClassName)
                .addClass("active");
            $(this).addClass("active");
            $("html, body").animate(
                { scrollTop: $(".caseFilter.active").offset().top - 210 },
                1000
            );
        });

        $(".header_stick").length &&
            $(window).scroll(function () {
                $(window).scrollTop()
                    ? $(".header_stick").addClass("navbar_fixed")
                    : $(".header_stick").removeClass("navbar_fixed");
            }),
            $(".bar_menu").length &&
                ($(".bar_menu").on("click", function () {
                    $("#menu").toggleClass("show-menu");
                }),
                $(".close_icon").on("click", function () {
                    $("#menu").removeClass("show-menu");
                })),
            $(".offcanfas_menu .dropdown").on("show.bs.dropdown", function (n) {
                $(this)
                    .find(".dropdown-menu")
                    .first()
                    .stop(!0, !0)
                    .slideDown(400);
            }),
            $(".offcanfas_menu .dropdown").on("hide.bs.dropdown", function (n) {
                $(this)
                    .find(".dropdown-menu")
                    .first()
                    .stop(!0, !0)
                    .slideUp(500);
            }),
            $(".box_item").hover(function () {
                $(".mid-activity")
                    .find(".active-img")
                    .removeClass("active-img"),
                    $(".features")
                        .find(".active-sec")
                        .removeClass("active-sec"),
                    $("#" + $(this).attr("data-img") + " img").addClass(
                        "active-img"
                    ),
                    $(this).addClass("active-sec");
            });

        $(function (e) {
            $("nav .navbar-nav a").each(function () {
                this.href === window.location.href &&
                    $(this).addClass("active");
            });
        });

        $(".box_item").hover(function () {
            $(".mid-activity").find(".active-img").removeClass("active-img"),
                $(".features").find(".active-sec").removeClass("active-sec"),
                $("#" + $(this).attr("data-img") + " img").addClass(
                    "active-img"
                ),
                $(this).addClass("active-sec");
        });

        $(".tabValues li").on("click", function () {
            var currentItem = "." + $(this).attr("attr");
            $(".tabValues").find(".active").removeClass("active");
            $(this).addClass("active");
            $(".pricingpage")
                .find(".showItem")
                .removeClass("showItem")
                .addClass("hideItem");
            $(currentItem).removeClass("hideItem").addClass("showItem");
        });

        /* Pricing js starts */

        var numberOfUsers = "";
        var numberOfUsers = $(".range-slider__range").val();
        var isPricingInUSD = true;
        /*no of users function*/
        $(".numberOfUserss").each(function () {
            $(this).text(numberOfUsers);
            $(".numberOfUserss").attr("users", numberOfUsers);
        });
        /*Price in boxes*/

        $(".output").each(function () {
            var selectedPlan = $(this).attr("plan");
            $(this).html(
                "<span>" +
                    `${getCurrencySymbol(isPricingInUSD)}` +
                    "</span>" +
                    getTotalPrice(selectedPlan, isPricingInUSD, numberOfUsers)
            );
        });

        /*Price per employee*/
        $(".pricePer").each(function () {
            var selectedPlan = $(this).attr("plan");
            var isPricingInUSD = true;
            $(this)
                .find(".amountperperson")
                .text(getPlanRate(selectedPlan, isPricingInUSD));
            $(".chooseplan").text(getPlanName(selectedPlan));
        });

        $("#subscriptionSwitch").click(function () {
            $(".tabValues").find(".active").removeClass("active");
            $(".subscriptiontab").addClass("active");
            $(".pricingpage")
                .find(".showItem")
                .removeClass("showItem")
                .addClass("hideItem");
            $(".subscription").removeClass("hideItem").addClass("showItem");
        });
        /*slider*/
        const settings = {
            fill: "#f8654a",
            background: "#f5f8fa",
        };
        const sliders = document.querySelectorAll(".range-slider");
        Array.prototype.forEach.call(sliders, (slider) => {
            slider.querySelector("input").addEventListener("input", (event) => {
                applyFill(slider.querySelector("input"));
                var numberOfUsers = event.target.value;
                $(".output").each(function () {
                    var selectedPlan = $(this).attr("plan");
                    var numberOfUsers = event.target.value;
                    var isPricingInUSD = true;
                    $(".noOfUsers").text(numberOfUsers);
                    $(".numberOfUserss").attr("users", numberOfUsers);
                    $(this).html(
                        "<span>" +
                            `${getCurrencySymbol(isPricingInUSD)}` +
                            "</span>" +
                            getTotalPrice(
                                selectedPlan,
                                isPricingInUSD,
                                numberOfUsers
                            )
                    );
                });
                $(".numberOfUserss").each(function () {
                    var numberOfUsers = event.target.value;
                    $(this).text(numberOfUsers);
                });
                if (numberOfUsers == 1000) {
                    $(".userLimit").show();
                } else {
                    $(".userLimit").hide();
                }
            });
            applyFill(slider.querySelector("input"));
        });

        $("#selectedNumberOfWeeks").change(function (plan, usd, numberOfUsers) {
            var selectedNumberOfWeeks = $(this).find("option:selected").val();
            var numberOfUsers = $(".numberOfUserss").attr("users");
            var selectedPlan = 1;
            var isPricingInUSD = true;
            getTotalPrice(selectedPlan, isPricingInUSD, numberOfUsers);
            $(".onetimeBox .output").html(
                "<span>" +
                    `${getCurrencySymbol(isPricingInUSD)}` +
                    "</span>" +
                    getTotalPrice(selectedPlan, isPricingInUSD, numberOfUsers)
            );
        });
        function getTotalPrice(plan, usd, numberOfUsers) {
            const cost = getCost(plan, usd, numberOfUsers);
            if (cost != null) {
                return `${cost}`;
            } else {
                return "Contact us";
            }
        }

        function getCost(plan, usd, numberOfUsers) {
            return (
                getPricePerUserPerWeek(plan, usd) *
                numberOfUsers *
                getWeeklyOrMonthlyFactor(plan)
            );
        }
        /* get price of plan*/
        function getPricePerUserPerWeek(plan, usd) {
            if (plan == 1) {
                if (usd) {
                    return 1;
                } else {
                    return 10;
                }
            } else if (plan == 2) {
                if (usd) {
                    return 1;
                } else {
                    return 10;
                }
            } else if (plan == 3) {
                if (usd) {
                    return 1.25;
                } else {
                    return 15;
                }
            } else {
                return 0;
            }
        }
        function getWeeklyOrMonthlyFactor(plan) {
            var selectedNumberOfWeeks = $(
                "#selectedNumberOfWeeks option:selected"
            ).val();
            if (plan == 1) {
                return selectedNumberOfWeeks;
            } else {
                return 4;
            }
        }

        /*
    0 = Free
    1 = One time challenge
    2 = Premium
    3 = Enterprise
     */

        /*Per employee Price*/
        function getPlanRate(plan, isPricingInUSD) {
            switch (plan) {
                case "0":
                    return "100 users";
                default:
                    var rateMultiplier = 1;
                    if (plan != 1) {
                        rateMultiplier = 4;
                    }
                    const rate =
                        `${getCurrencySymbol(isPricingInUSD)}` +
                        `${
                            getPricePerUserPerWeek(plan, isPricingInUSD) *
                            rateMultiplier
                        } per employee`;
                    if (plan == 1) {
                        return rate + "/week";
                    } else {
                        return rate;
                    }
            }
        }

        function getCurrencySymbol(usd) {
            if (usd) {
                return "$";
            } else {
                return "₹";
            }
        }
        function getPlanName(plan) {
            switch (plan) {
                case 0:
                    return "FREE";
                case 1:
                    return "One Time Challenge";
                case 2:
                    return "Premium";
                case 3:
                    return "Enterprise";
            }
        }
        /*color of slider */
        function applyFill(slider) {
            const percentage =
                (100 * (slider.value - slider.min)) / (slider.max - slider.min);
            const bg = `linear-gradient(90deg, ${
                settings.fill
            } ${percentage}%, ${settings.background} ${percentage + 0.1}%)`;
            slider.style.background = bg;
        }

        /*Mobile View */
        $("#subscriptionPlan .indItems.tabsViewMobile div").click(function () {
            var className = $(this).attr("attr");
            $("#subscriptionPlan .tableSubscription td").hide();
            $(
                "#subscriptionPlan .indItems div, #subscriptionPlan .tableSubscription div"
            ).removeClass("active");
            $("#subscriptionPlan .tableSubscription td:first-child").show();
            $(
                "#subscriptionPlan .tableSubscription" +
                    " ." +
                    className +
                    "Plan"
            ).show();

            $(this).addClass("active");
            $(".mobileView").find(".active").removeClass("active");
            $(".mobileView" + "." + className + "Plan").addClass("active");
        });

        /*tabs*/
        $(".tabValues li").on("click", function () {
            var currentItem = "." + $(this).attr("attr");
            $(".tabValues").find(".active").removeClass("active");
            $(this).addClass("active");
            $(".pricingpage")
                .find(".showItem")
                .removeClass("showItem")
                .addClass("hideItem");
            $(currentItem).removeClass("hideItem").addClass("showItem");
        });
        /*
    0 = Free
    1 = One time challenge
    2 = Premium
    3 = Enterprise


    /*calculation

    1,200 = 200 * 4 = 400 (for plan 1, 200 User 2 weeks)
    1,200 = 200 * 4 = 800 (for plan 1, 200 User 4 weeks)
    1,200 = 200 * 4 = 1600 (for plan 1, 200 User 8 weeks)
    1,200 = 200 * 4 = 2400 (for plan 1, 200 User 16 weeks)
    2,200 = 1 * 200 = 200 * 4 = 800(for plan 2, 200 User)
    3,200 = 1.25 *200=250 *4 = 1000(plan 3 200 users)


    1,300 = 300 * 2 = 600 (plan 1 , 300 users, 2 weeks)
    1,300 = 300 * 8 = 2400 (plan 1 , 300 users. 4 weks)
    2,300 =1 * 300 = 300 * 4= 1200 (plan 2 , 300 users)
    3,300 = 1.25 *300 =375*4=1500 ((plan 3 , 300 users))

     */
        /* Pricing js ends */

        var hellobarStatus = sessionStorage.getItem(`helloBar`);
        // if (hellobarStatus == "false") {
        //     $("#maincont").removeClass("mainpid");
        // } else if (!removeHellobarUrl.includes(url)) {
        //     $("#maincont").addClass("mainpid");
        // }
    });
};

// Adding scroll event listener
// document.addEventListener('scroll', horizontalScroll);

// let sticky = document.querySelector('.stickys');
// let stickyParent = document.querySelector('.sticky-parent');

// let scrollWidth = sticky.scrollWidth;
// let verticalScrollHeight = stickyParent.getBoundingClientRect().height-sticky.getBoundingClientRect().height;

// function horizontalScroll(){

//     let stickyPosition = sticky.getBoundingClientRect().top;
//     if(stickyPosition > 1){
//         return;
//     }else{
//         let scrolled = stickyParent.getBoundingClientRect().top;
//         sticky.scrollLeft =(scrollWidth/verticalScrollHeight)*(-scrolled)*0.85;

//     }
// }

/* CircularProgressScripts starts */
var circularProgressScripts = function () {
    $.fn.circleGraphic = function (options) {
        $.fn.circleGraphic.defaults = {
            color: "#05c8ad",
            startAngle: 0,
            //endAngle:50
        };
        $(this).each(function () {
            let $this = $(this);
            var opts = $.extend({}, $.fn.circleGraphic.defaults, options);
            var percentage = $(this).find(".circle").attr("data-percent");
            var ID = "c" + percentage + Math.random();
            $this.append(
                "<canvas width='200' height='200' id='" + ID + "'></canvas>"
            );
            var canvas = document.getElementById(ID),
                context = canvas.getContext("2d");
            var Width = $this.width();
            $this.height(Width);
            var Height = $this.height();

            canvas.width = Width;
            canvas.height = Height;
            var startAngle = opts.startAngle,
                endAngle = percentage / 100,
                angle = startAngle,
                radius = Width * 0.4;

            function drawTrackArc() {
                context.beginPath();
                context.strokeStyle = "#ecfdfb";
                context.lineWidth = 7;
                context.arc(
                    Width / 2,
                    Height / 2,
                    radius,
                    (Math.PI / 180) * (startAngle * 360 - 90),
                    (Math.PI / 180) * (endAngle * 360 + 270),
                    false
                );
                context.stroke();
                context.closePath();
            }

            function drawOuterArc(_angle, _color) {
                var angle = _angle;
                var color = _color;
                context.beginPath();
                context.strokeStyle = color;
                context.lineWidth = 7;

                context.font = "300 70px Poppins,sans-serif";
                context.arc(
                    Width / 2,
                    Height / 2,
                    radius,
                    (Math.PI / 180) * (startAngle * 360 - 90),
                    (Math.PI / 180) * (angle * 360 - 90),
                    false
                );
                context.stroke();
                context.closePath();
            }

            function numOfPercentage(_angle, _color) {
                var angle = Math.ceil(_angle * 100);
                var color = _color;
                context.fillStyle = "#4a5269";
                var metrics = context.measureText(angle);
                var textWidth = metrics.width;
                var xPos = Width / 2 - textWidth / 2,
                    yPos = Height / 2 + textWidth / 2;
                context.textAlign = "center";
                context.direction = "ltr";
                context.fillText(angle + "%", 137, 157);
            }

            function drawACircleInTheEnd() {
                let radians = angle * 2 * Math.PI;
                context.beginPath();
                context.arc(
                    Width / 2 + radius * Math.sin(radians),
                    Height / 2 - radius * Math.cos(radians),
                    3,
                    0,
                    2 * Math.PI,
                    false
                );

                context.fillStyle = "#05c8ad";
                context.fill();
                context.lineWidth = 7;
                context.strokeStyle = "#05c8ad";
                context.stroke();
            }

            function draw() {
                var loop = setInterval(function () {
                    var circlebar = $(".circleGraphic");
                    if (circlebar.length) {
                        var elementPos = circlebar.offset().top;
                        var topOfWindow = $(window).scrollTop();
                        var animate = $(".circleGraphic").data("animate");
                        if (
                            elementPos <
                                topOfWindow + $(window).height() - 30 &&
                            !animate
                        ) {
                            context.clearRect(0, 0, Width, Height);
                            drawTrackArc();
                            drawOuterArc(angle, opts.color);
                            numOfPercentage(angle, opts.color);
                            drawACircleInTheEnd();
                            angle += 0.01;
                            if (angle > endAngle) {
                                clearInterval(loop);
                            }
                        }
                    }
                }, 1000 / 60);
            }
            draw();
            return $this;
        });
    };

    $(".circleGraphic").circleGraphic();
};
/* CircularProgressScripts ends */

var trustAllScripts1 = function () {
    var scriptNodes = document.querySelectorAll(
        ".load-external-scripts script"
    );

    for (var i = 0; i < scriptNodes.length; i += 1) {
        var node = scriptNodes[i];
        var s = document.createElement("script");
        s.type = node.type || "text/javascript";

        if (node.attributes.src) {
            s.src = node.attributes.src.value;
        } else {
            s.innerHTML = node.innerHTML;
        }

        document.getElementsByTagNam$("head")[0].appendChild(s);
    }
    const urls = ["https://www.vantagefit.io/"];
    // const ldjson = [
    //   `
    //     {
    //       "@context": "http://schema.org",
    //       "@graph": [
    //           {
    //       "@type": "WebSite",
    //       "name": "Vantage Fit",
    //       "url": "https://www.vantagefit.io/",
    //       "inLanguage": "en-US"
    //       },
    //       {
    //       "@id": "https://www.vantagefit.io/#organization",
    //       "@type": "Organization",
    //       "name": "Vantage Fit",
    //       "parentOrganization": "Vantage Circle",
    //       "description": "Vantage Fit is an AI Powered Corporate wellness solution to enhance employee wellbeing and productivity.",
    //       "url": "https://www.vantagefit.io",
    //       "foundingDate": "2017",
    //       "logo": {
    //       "@type": "ImageObject",
    //       "url": "https://res.cloudinary.com/vantagecircle/image/upload/w_220/VantageFit/website/01-vantagefit-logo-2_compress.png",
    //       "width": 300,
    //       "height": 44
    //       },
    //       "address":
    //      [
    //        {
    //          "@type": "PostalAddress",
    //          "streetAddress": "C1-1151/Vasant Kunj",
    //          "addressLocality": "New Delhi",
    //          "postalCode": "110070",
    //          "addressCountry": "IN"
    //         },
    //         {
    //          "@type": "PostalAddress",
    //          "streetAddress": "4512 Legacy Drive, Suite100, Plano",
    //          "addressLocality": "Texas",
    //             "postalCode": "75024",
    //             "addressCountry": "US"
    //           },
    //           {
    //             "@type": "PostalAddress",
    //             "streetAddress": "Suite 1A, Level 2, 802-808 Pacific Highway, Gordon,",
    //             "addressLocality": "New South Wales",
    //             "postalCode": "2072",
    //             "addressCountry": "AU"
    //           },
    //           {
    //             "@type": "PostalAddress",
    //             "streetAddress": "Suite 1224, 211 Aspen Stone Blvd,",
    //             "addressLocality": "Calgary",
    //             "postalCode": "T3H0K1",
    //             "addressCountry": "CA"
    //           },
    //           {
    //             "@type": "PostalAddress",
    //             "streetAddress": "Startbaan 10 G, Amstelveen,",
    //             "addressLocality": "Netherlands",
    //             "postalCode": "1185 XR",
    //             "addressCountry": "NL"
    //           }
    //         ],
    //           "contactPoint" : [
    //             {
    //               "@type": "ContactPoint",
    //               "contactType": "customer support",
    //               "areaServed" : ["AF","NA","OC","AS","EU","SA"],
    //               "availableLanguage" : ["English"],
    //               "email": "support@vantagecircle.com"
    //             }
    //           ],
    //           "sameAs": [
    //             "https://twitter.com/FitVantage/",
    //             "https://linkedin.com/showcase/vantagefit",
    //             "https://www.facebook.com/Vantagefit-114318070217339/",
    //             "https://www.youtube.com/channel/UCA_X-JntyyNU6rjeRQWHE2w/"
    //           ]
    //         }
    //       ]
    //     }`
    // ]
    // const script = document.createElement(`script`)
    // script.type = "application/ld+json"
    // const json = ldjson
    // document.getElementsByTagName(`head`)[0].appendChild(script).append(json)
};

// trustAllScripts1();
// customScripts();
// circularProgressScripts();

const jQuery = require("jquery");

var tabsScroll = function () {
    $.fn.horizontalTabs = function () {
        // Variable creation
        var $elem = $(this),
            widthOfReducedList = $elem.find(".nav-tabs-horizontal").width(),
            widthOfList = 0,
            currentPos = 0,
            adjustScroll = function () {
                widthOfList = 0;
                $elem
                    .find(".nav-tabs-horizontal li")
                    .each(function (index, item) {
                        widthOfList += $(item).width();
                    });

                widthAvailale = $elem.width();

                if (widthOfList > widthAvailale) {
                    $elem.find(".scroller").show();
                    updateArrowStyle(currentPos);
                    widthOfReducedList = $elem
                        .find(".nav-tabs-horizontal")
                        .width();
                } else {
                    $elem.find(".scroller").hide();
                }
            },
            scrollLeft = function () {
                $elem.find(".nav-tabs-horizontal").animate(
                    {
                        scrollLeft: currentPos - widthOfReducedList,
                    },
                    500
                );

                if (currentPos - widthOfReducedList > 0) {
                    currentPos -= widthOfReducedList;
                } else {
                    currentPos = 0;
                }
            },
            scrollRight = function () {
                $elem.find(".nav-tabs-horizontal").animate(
                    {
                        scrollLeft: currentPos + widthOfReducedList,
                    },
                    500
                );

                if (
                    currentPos + widthOfReducedList <
                    widthOfList - widthOfReducedList
                ) {
                    currentPos += widthOfReducedList;
                } else {
                    currentPos = widthOfList - widthOfReducedList;
                }
            },
            manualScroll = function () {
                currentPos = $elem.find(".nav-tabs-horizontal").scrollLeft();

                updateArrowStyle(currentPos);
            },
            updateArrowStyle = function (position) {
                if (position >= widthOfList - widthOfReducedList) {
                    $elem.find(".arrow-right").addClass("disabled");
                } else {
                    $elem.find(".arrow-right").removeClass("disabled");
                }

                if (position <= 0) {
                    $elem.find(".arrow-left").addClass("disabled");
                } else {
                    $elem.find(".arrow-left").removeClass("disabled");
                }
            };

        // Event binding
        $(window).resize(function () {
            adjustScroll();
        });

        $elem.find(".arrow-left").on("click.horizontalTabs", function () {
            scrollLeft();
        });

        $elem.find(".arrow-right").on("click.horizontalTabs", function () {
            scrollRight();
        });

        $elem.find(".nav-tabs-horizontal").scroll(function () {
            manualScroll();
        });

        // Initial Call
        adjustScroll();

        return this;
    };

    $("#js_image_selection").horizontalTabs();
};

var customjquery = function () {
    jQuery(document).ready(function ($) {
        $(".contentpost img").each(function () {
            $(this).attr("width", "500");
            $(this).attr("height", "400");
            $(this).attr("loading", "lazy");
            $(this).attr("class", "lozad");
        });
        $(".contentpost iframe").each(function () {
            $(this).attr("loading", "lazy");
            $(this).attr("class", "lozad");
        });

        $("amp-img").removeAttr("loading");
        // $("img").addClass("lozad");
        // $(".load-external-scripts img").removeAttr("loading");
        // $("amp-img").addClass("lozad");

        var popupRequestdemo = localStorage.getItem(`showMsg`);
        if (popupRequestdemo == "false") {
            $("#footerb").removeClass(`popupspc`);
        }
        $(".content_c ol a").click(function () {
            var href = $(this).attr("href");
            $("html, body").animate(
                { scrollTop: $(href).offset().top - 130 },
                500
            );
        });
        $(".all").addClass("showTags");
        var IDs = [];
        $(".categories_filtertags > li").on("click", function () {
            $(".contentpost")
                .find(".all")
                .removeClass("showTags")
                .addClass("hideTag");
            $("#all").removeClass("active");
            $(this).toggleClass("active");
            var nameId = $(".categories_filtertags")
                .find(".active")
                .map(function () {
                    return this.id;
                })
                .get();
            for (var i = 0; i < nameId.length; i++) {
                $(".contentpost")
                    .find("." + nameId[i])
                    .addClass("showTags");
            }
            if (nameId == "") {
                $(".all").addClass("showTags");
                $("#all").addClass("active");
            }
        });
        $(".mobile_listing").click(function () {
            $(this).find(".dropdownmenu").toggleClass("mobileMenuD");
        });
        $(".listingMenu > a").click(function (event) {
            event.preventDefault();
        });

        $(".header_stick").length &&
            $(window).scroll(function () {
                $(window).scrollTop()
                    ? $(".header_stick").addClass("navbar_fixed")
                    : $(".header_stick").removeClass("navbar_fixed");
            }),
            $(".bar_menu").length &&
                ($(".bar_menu").on("click", function () {
                    $("#menu").toggleClass("show-menu");
                    $("#navbarSupportedContent").toggleClass("show-menu");
                }),
                $(".close_icon").on("click", function () {
                    $("#menu").removeClass("show-menu");
                })),
            $(".offcanfas_menu .dropdown").on("show.bs.dropdown", function (n) {
                $(this)
                    .find(".dropdown-menu")
                    .first()
                    .stop(!0, !0)
                    .slideDown(400);
            }),
            $(".offcanfas_menu .dropdown").on("hide.bs.dropdown", function (n) {
                $(this)
                    .find(".dropdown-menu")
                    .first()
                    .stop(!0, !0)
                    .slideUp(500);
            }),
            $(".box_item").hover(function () {
                $(".mid-activity")
                    .find(".active-img")
                    .removeClass("active-img"),
                    $(".features")
                        .find(".active-sec")
                        .removeClass("active-sec"),
                    $("#" + $(this).attr("data-img") + " img").addClass(
                        "active-img"
                    ),
                    $(this).addClass("active-sec");
            });

        $(function (e) {
            $("nav .navbar-nav a").each(function () {
                this.href === window.location.href &&
                    $(this).addClass("active");
            });
        });

        $("#searchcall").on("click", function (event) {
            $("#search").css("display", "block");
            $("#search").addClass("show");
            $("#modalback").addClass("modal-backdrop");
            $("#modalback").addClass("fade");
            $("#modalback").addClass("show");
            $("#gatsbyBody").addClass("showClick");
        });

        $("#search_close").on("click", function (event) {
            $("#search").css("display", "none");
            $("#search").removeClass("show");
            $("#modalback").removeClass("modal-backdrop");
            $("#modalback").removeClass("fade");
            $("#modalback").removeClass("show");
            $("#gatsbyBody").removeClass("showClick");
        });

        $(document).on("click", function (e) {
            $('[data-toggle="popover"],[data-original-title]').each(
                function () {
                    if (
                        !$(this).is(e.target) &&
                        $(this).has(e.target).length === 0 &&
                        $(".popover").has(e.target).length === 0
                    ) {
                        // (($(this).popover('hide').data('bs.popover')||{}).inState||{}).click = false
                    }
                }
            );
        });

        var didScroll,
            lastScrollTop = 0,
            delta = 5;

        // Execute on load
        $(window).on("load", function (event) {
            var currentPage = 1;
            var pathname = window.location.pathname;
            var $result = $(".post");
            var step = 0;

            // remove hash params from pathname
            pathname = pathname.replace(/#(.*)$/g, "").replace("///g", "/");

            if ($("body").hasClass("paged")) {
                currentPage = parseInt(pathname.replace(/[^0-9]/gi, ""));
            }
        });

        $("#search").on("shown.bs.modal", function () {
            $("#search-field").focus();
        });

        // Initialize Highlight.js

        $("pre code, pre").each(function (i, block) {
            hljs.highlightBlock(block);
        });

        $(window).on("scroll", function (event) {
            if ($(this).scrollTop() > 0) {
                $("body").addClass("scroll");
            } else {
                $("body").removeClass("scroll");
            }
        });

        // Check if element is into view when scrolling
        function isScrolledIntoView(elem) {
            var docViewTop = $(window).scrollTop();
            var docViewBottom = docViewTop + $(window).height();

            var elemTop = $(elem).offset().top;
            var elemBottom = elemTop + $(elem).height();

            return elemBottom <= docViewBottom && elemTop >= docViewTop;
        }

        // Validate subscribe form
        $(".gh-signin").each(function (index, el) {
            $(this).validate({
                rules: {
                    email: {
                        required: true,
                        email: true,
                    },
                },
                submitHandler: function (form) {
                    $(form).submit();
                },
            });
        });

        // Validate formspree form
        $("form[action*='https://formspree.io/']").each(function (index, el) {
            $(this).validate({
                rules: {
                    email: {
                        required: true,
                        email: true,
                    },
                    message: {
                        required: true,
                    },
                },
            });
        });
        if ($(".error-title").length) {
            $("body").addClass("error");
        }
    });
};

var trustAllScripts = function () {
    var scriptNodes = document.querySelectorAll(
        ".load-external-scripts script"
    );

    for (var i = 0; i < scriptNodes.length; i += 1) {
        var node = scriptNodes[i];
        var s = document.createElement("script");
        s.type = node.type || "text/javascript";

        if (node.attributes.src) {
            s.src = node.attributes.src.value;
        } else {
            s.innerHTML = node.innerHTML;
        }

        document.getElementsByTagName("head")[0].appendChild(s);
    }
};

/*exit popup*/
$(document).on("mouseleave", leaveFromTop);
var showExitPopup;
$("#ouibounce-modal .modal").hide();
function leaveFromTop(e) {
    if (e.clientY < 0) {
        const showExitPopup = sessionStorage.getItem("showExitPopup");
        if (showExitPopup !== "false") {
            $("#ouibounce-modal").show();
            $("#ouibounce-modal .modal").show();
        }
        $("#ouibounce-modal .close .cross").click(function () {
            $("#ouibounce-modal .modal").hide();
            sessionStorage.setItem("showExitPopup", "false");
            $(".underlay").hide();
            $("#ouibounce-modal").hide();
        });
        if (showExitPopup == "false") {
            $("#ouibounce-modal .modal").hide();
            $(".underlay").hide();
            $("#ouibounce-modal").hide();
        }
    }
}

exports.onRouteUpdate = function () {
    trustAllScripts1();
    customScripts();
    circularProgressScripts();
    trustAllScripts();
    customjquery();
    tabsScroll();
};

/* wista custom */
const playWista = () => {
    $(".wistia_embed").css("display", "block");
    // const s =
    // document.getElementsByClassName(`wistia_embed`);
};

if (document.URL.indexOf("/amp/") <= -1) {
    window.onload = function () {
        setTimeout(playWista, 4000);
        //console.log(playWista)
    };
}
/*wista custom ends*/

//add  revenueHero script
let addRevenueHeroScriptPromise = new Promise(function (resolve, reject) {
    // Create a new script element and set its attributes
    var script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://app.revenuehero.io/scheduler.min.js";
    // Append the script element to the head element of the document
    var head = document.getElementsByTagName("head")[0];
    head.appendChild(script);
    script.onload = function () {
        resolve();
    };
    script.onerror = function () {
        reject("rejected");
    };
});

//revenue hero code
// function executeRevenueHeroScript() {
//   ;(function () {
//     var targetFormIds = [
//       `c169c509-7422-4098-bdd2-085a67d82092`
//     ]
//     if (typeof window === "undefined") return
//     if (window.addEventListener) {
//       window.addEventListener("message", handleFormSubmitEvent, false)
//     } else if (window.attachEvent) {
//       window.attachEvent("onmessage", handleFormSubmitEvent)
//     }
//     function handleFormSubmitEvent(event) {
//       if (
//         event.data.type === "hsFormCallback" &&
//         event.data.eventName === "onFormSubmit"
//       ) {
//         var submittedData = event.data.data

//         // Extract the form ID from the hs_context field value
//         var hsContextField = submittedData.find(function (field) {
//           return field.name === "hs_context"
//         })

//         if (hsContextField) {
//           var hsContextValue = JSON.parse(hsContextField.value)
//           var formId = hsContextValue.originalEmbedContext.formId

//           // Check if the submitted form's ID is in the targetFormIds array
//           if (targetFormIds.includes(formId)) {
//             var formValues = {}
//             submittedData.forEach(function (field) {
//               formValues[field.name] = field.value
//             })
//             //replace routerID with the router ID you've setup
//             const RevenueHero = window.RevenueHero
//             const hero = new RevenueHero({ routerId: `132` })
//             hero.submit(formValues).then(sessionData => {
//               hero.dialog.open(sessionData)
//             })
//           }
//         }
//       }
//     }
//   })()
// }

exports.onClientEntry = () => {
    //cookie consent
    checkCurrCountry().then((country) => {
        let allowCookie = false;
        let showConsentBar = true;
        let storedState = JSON.parse(
            localStorage.getItem("cookieConsentConfig")
        );
        if (storedState == null) {
            setInitialLocalStorageStates(country, allowCookie, showConsentBar);
        }
        let gdprSessionStorageState = window.sessionStorage.getItem("allowGtm");

        if (gdprSessionStorageState == "true") {
            setGtm();
        }

        createCustomEventToCheckLocalStorageChanges();
        window.addEventListener(
            "storage",
            () => {
                let gdprSessionStorageState =
                    window.sessionStorage.getItem("allowGtm");
                if (gdprSessionStorageState == true) {
                    setGtm();
                } else {
                    return;
                }
            },
            false
        );
    });

    //revenue hero code
    addRevenueHeroScriptPromise
        .then(executeRevenueHeroScript())
        .catch((error) => console.log(error));
};

//revenue hero code
function executeRevenueHeroScript() {
    var targetFormIds = [
        `517ebc0a-e3b2-48e7-994d-19507b711e00`,
        `c169c509-7422-4098-bdd2-085a67d82092`,
        `e23ab1ed-02c7-42c1-90bc-ecd78ae786c3`,
        `3ba0360d-0fb0-4473-84c9-a7362657431c`,
    ];
    if (typeof window === "undefined") return;
    if (window.addEventListener) {
        window.addEventListener("message", handleFormSubmitEvent, false);
    } else if (window.attachEvent) {
        window.attachEvent("onmessage", handleFormSubmitEvent);
    }
    function handleFormSubmitEvent(event) {
        if (
            event.data.type === "hsFormCallback" &&
            event.data.eventName === "onFormSubmitted"
        ) {
            var submittedData = event.data.data.submissionValues;
            var formId = event.data.id;
            console.log(formId);
            // Check if the submitted form's ID is in the targetFormIds array
            if (targetFormIds.includes(formId)) {
                var formValues = event.data.data.submissionValues;
                //replace routerID with the router ID you've setup
                const RevenueHero = window.RevenueHero;
                const hero = new RevenueHero({ routerId: `1226` });
                hero.submit(formValues).then((sessionData) => {
                    hero.dialog.open(sessionData);
                });
            }
        }
    }
}

//default id for english site
let setID = `TDN6QG5`;

const setGtm = () => {
    insertJS();
};

const addgtmLink = `https://www.googletagmanager.com/gtm.js?id=GTM-${setID}`;

//add gtm
const insertJS = () => {
    const addIframe = (src) => {
        const s = document.createElement(`noscript`);
        const iframe = document.createElement(`iframe`);
        iframe.src = src;
        iframe.height = "0";
        iframe.style = "visiblity:hidden";
        document.getElementsByTagName(`body`)[0].appendChild(s)
            .append(`<iframe  src="https://www.googletagmanager.com/ns.html?id=GTM-${setID}"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`);
    };
    const insertF = () => {
        const s = document.createElement(`script`);
        const src = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-${setID}');`;
        document.getElementsByTagName(`head`)[0].appendChild(s).append(src);
    };

    addIframe(addgtmLink);
    insertF();
};

const checkCurrCountry = async () => {
    try {
        let requestUrl = "https://geolocation-db.com/json/";
        let res = await axios.get(requestUrl);
        currentCountry = await res.data.country_name;
        return currentCountry;
    } catch (error) {
        console.log(error);
    }
};

const createCustomEventToCheckLocalStorageChanges = () => {
    var evt = document.createEvent("StorageEvent");
    evt.initStorageEvent(
        "storage",
        false,
        false,
        "allowGtm",
        "oldValue",
        "newValue",
        null,
        window.localStorage
    );
    window.dispatchEvent(evt);
};

const setInitialLocalStorageStates = (...args) => {
    const [country, allowCookie, showConsentBar] = args;
    let initialObj = JSON.stringify({
        showConsentBar: showConsentBar,
        allowCookie: allowCookie,
        country: country,
    });
    localStorage.setItem("cookieConsentConfig", initialObj);
    sessionStorage.setItem("allowGtm", "false");
    console.log("run", initialObj);
};
