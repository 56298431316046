exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-vfit-newyear-campaign-js": () => import("./../../../src/pages/vfit-newyear-campaign.js" /* webpackChunkName: "component---src-pages-vfit-newyear-campaign-js" */),
  "component---src-templates-article-template-js": () => import("./../../../src/templates/article-template.js" /* webpackChunkName: "component---src-templates-article-template-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-blog-templates-blog-template-de-js": () => import("./../../../src/templates/blog_templates/blogTemplateDe.js" /* webpackChunkName: "component---src-templates-blog-templates-blog-template-de-js" */),
  "component---src-templates-blog-templates-blog-template-es-js": () => import("./../../../src/templates/blog_templates/blogTemplateEs.js" /* webpackChunkName: "component---src-templates-blog-templates-blog-template-es-js" */),
  "component---src-templates-blog-templates-blog-template-fr-js": () => import("./../../../src/templates/blog_templates/blogTemplateFr.js" /* webpackChunkName: "component---src-templates-blog-templates-blog-template-fr-js" */),
  "component---src-templates-blog-templates-index-js": () => import("./../../../src/templates/blog_templates/index.js" /* webpackChunkName: "component---src-templates-blog-templates-index-js" */),
  "component---src-templates-blog-templates-page-js": () => import("./../../../src/templates/blog_templates/page.js" /* webpackChunkName: "component---src-templates-blog-templates-page-js" */),
  "component---src-templates-blog-templates-podcast-single-js": () => import("./../../../src/templates/blog_templates/podcastSingle.js" /* webpackChunkName: "component---src-templates-blog-templates-podcast-single-js" */),
  "component---src-templates-blog-templates-post-js": () => import("./../../../src/templates/blog_templates/post.js" /* webpackChunkName: "component---src-templates-blog-templates-post-js" */),
  "component---src-templates-blog-templates-tag-js": () => import("./../../../src/templates/blog_templates/tag.js" /* webpackChunkName: "component---src-templates-blog-templates-tag-js" */),
  "component---src-templates-case-study-single-js": () => import("./../../../src/templates/caseStudySingle.js" /* webpackChunkName: "component---src-templates-case-study-single-js" */),
  "component---src-templates-feature-page-es-js": () => import("./../../../src/templates/featurePageEs.js" /* webpackChunkName: "component---src-templates-feature-page-es-js" */),
  "component---src-templates-feature-page-fr-js": () => import("./../../../src/templates/featurePageFr.js" /* webpackChunkName: "component---src-templates-feature-page-fr-js" */),
  "component---src-templates-feature-page-js": () => import("./../../../src/templates/featurePage.js" /* webpackChunkName: "component---src-templates-feature-page-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-resource-template-de-js": () => import("./../../../src/templates/resource-template-de.js" /* webpackChunkName: "component---src-templates-resource-template-de-js" */),
  "component---src-templates-resource-template-es-js": () => import("./../../../src/templates/resource-template-es.js" /* webpackChunkName: "component---src-templates-resource-template-es-js" */),
  "component---src-templates-resource-template-fr-js": () => import("./../../../src/templates/resource-template-fr.js" /* webpackChunkName: "component---src-templates-resource-template-fr-js" */),
  "component---src-templates-tag-js": () => import("./../../../src/templates/tag.js" /* webpackChunkName: "component---src-templates-tag-js" */),
  "slice---src-components-blog-components-common-simple-footer-js": () => import("./../../../src/components/blog_components/common/SimpleFooter.js" /* webpackChunkName: "slice---src-components-blog-components-common-simple-footer-js" */),
  "slice---src-components-common-footer-js": () => import("./../../../src/components/common/Footer.js" /* webpackChunkName: "slice---src-components-common-footer-js" */)
}

